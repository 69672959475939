import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
// Home
import Home from "../views/Home.vue";

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start Profiles ==========

// ========== End Profiles ==========

// ============================== START:: CRUDS ==============================

// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";
// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
          redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: "/tickets/show-all" };
          },
        },

        // ******* Start Orders *******
        {
          path: "/trips",
          component: () => import("../views/children/CRUDS/Trips/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllTrips",
              component: () =>
                import("../views/children/CRUDS/Trips/ShowAll.vue"),
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/children/CRUDS/Trips/Show/ShowHome.vue"),

              props: true,
              children: [
                {
                  path: "",
                  name: "OrderDetails",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderDetails.vue"
                    ),

                  props: true,
                },
                {
                  path: "invoice",
                  name: "invoice",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Invoice.vue"),

                  props: true,
                },
                {
                  path: "orderChat",
                  name: "OrderChat",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderChat.vue"),

                  props: true,
                },
                {
                  path: "offersChat/:offer_id",
                  name: "OffersChat",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OffersChat.vue"),

                  props: true,
                },
                {
                  path: "partner",
                  name: "partner",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Partner.vue"),

                  props: true,
                },
                {
                  path: "reportReason",
                  name: "ReportReason",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/ReportReason.vue"
                    ),

                  props: true,
                },
                {
                  path: "complaint",
                  name: "Complaint",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/Complaint.vue"),

                  props: true,
                },
                {
                  path: "orderOffers",
                  name: "OrderOffers",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderOffers.vue"),

                  props: true,
                },
                {
                  path: "orderRates",
                  name: "OrderRates",
                  component: () =>
                    import("@/views/children/CRUDS/Trips/Show/OrderRates.vue"),

                  props: true,
                },
                {
                  path: "orderPayment",
                  name: "OrderPayment",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderPayment.vue"
                    ),

                  props: true,
                },
                {
                  path: "orderPackage",
                  name: "OrderPackage",
                  component: () =>
                    import(
                      "@/views/children/CRUDS/Trips/Show/OrderPackage.vue"
                    ),

                  props: true,
                },
              ],
            },
            {
              path: "user_orders/:id/:type",
              name: "AllUserOrders",
              component: () =>
                import("@/views/children/CRUDS/Trips/AllUserOrders.vue"),
              props: true,
            },
            {
              path: "complaint-trips",
              name: "AllComplaintTrips",
              component: () =>
                import("@/views/children/CRUDS/Trips/Complaint.vue"),
            },
            {
              path: "unusual_behaviour",
              name: "unusual_behaviour",
              component: () =>
                import("@/views/children/CRUDS/Trips/UnusualBehaviour.vue"),
            },
          ],
        },
        // ******* End Orders *******

        // ******* Start Admins *******
        {
          path: "/tickets",
          component: () => import("@/views/children/CRUDS/Tickets/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "Tickets",
              component: () =>
                import("@/views/children/CRUDS/Tickets/ShowAll.vue"),
            },
            {
              path: "agent-tickets",
              name: "agent-tickets",
              component: () =>
                import("@/views/children/CRUDS/Tickets/MyTickets.vue"),
            },
            {
              path: "active-tickets",
              name: "active-tickets",
              component: () =>
                import("@/views/children/CRUDS/Tickets/ActiveTickets.vue"),
            },
            // Show Single
            // {
            //   path: "show/:id",
            //   name: "AdminShow",
            //   component: () => import("@/views/children/CRUDS/Tickets/Show.vue"),
            //   props: true,
            // },
            // // Edit
            {
              path: "reply/:id",
              name: "Reply",
              component: () =>
                import("@/views/children/CRUDS/Tickets/Form.vue"),
              props: true,
            },
            // // Add
            // {
            //   path: "add",
            //   name: "AdminAdd",
            //   component: () => import("@/views/children/CRUDS/Tickets/Form.vue"),
            // },
          ],
        },
        // ******* End Admins *******

        // ========== Start Profiles ==========
        {
          path: "/profile",

          component: () => import("@/views/children/adminProfile/Home.vue"),
          children: [
            {
              path: "show",
              name: "showProfile",
              component: () => import("@/views/children/adminProfile/Show.vue"),
            },
            {
              path: "edit",
              name: "editProfile",
              component: () => import("@/views/children/adminProfile/Form.vue"),
            },
            {
              path: "password",
              name: "changePassword",
              component: () =>
                import("@/views/children/adminProfile/ChangePassword.vue"),
            },
          ],
        },
        // ========== End Profiles ==========
      ],
    },
    {
      path: "/permission-required",
      name: "permission-required",
      component: () => import("../views/PermissionRequired.vue"),
    },

    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, _2, next) => {
  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
