import axios from "axios";

export default {
  getNotifications(context) {
    axios.get("notification").then((res) => {
      context.commit("setNotifications", res.data.data);
      context.commit("setUnread", res.data.unread_count);
    });
  },
};

