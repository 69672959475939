<template>
  <nav
    class="main_nav d-flex justify-content-between align-items-end flex-column"
  >
    <div class="navContent flex-wrap gap-3 w-100">
      <div class="user" v-if="userDataExist" @click.stop="toggle_profile_menu">
        <div class="image">
          <img :src="userDataExist.image" />
          <!-- Profile dropdown -->
          <transition name="fadeUpTransform">
            <div
              v-if="profile_menu"
              class="custom_card profile_dropdown nav_dropdown"
            >
              <ul>
                <!-- <li>
                  <router-link to="/profile/show">
                    <span class="icon"></span>
                    <span class="text">
                      {{ $t("navbar.userMenu.profile") }}
                    </span>
                  </router-link>
                </li> -->
                <li>
                  <router-link to="/profile/edit">
                    <span class="icon"></span>
                    <span class="text">
                      {{ $t("navbar.userMenu.editProfile") }}
                    </span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/password">
                    <span class="icon"></span>
                    <span class="text">
                      {{ $t("navbar.userMenu.updatePassword") }}
                    </span>
                  </router-link>
                </li>
                <!-- <li>
                    <router-link to="/chat">
                      <span class="icon"></span>
                      <span class="text">
                        {{ $t('navbar.userMenu.chats') }}
                      </span>
                    </router-link>
                  </li> -->
                <v-divider></v-divider>
                <li @click="logOut">
                  <span class="icon"></span>
                  <span class="text">{{ $t("navbar.userMenu.logout") }}</span>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <div class="text">
          <span class="name">{{ userDataExist.full_name }}</span>
          <span class="available">{{ userDataExist.phone }}</span>
        </div>
        <a role="button" class="me-2"
          ><i
            class="fas"
            :class="profile_menu ? ' fa-angle-up' : 'fa-angle-down'"
          ></i
        ></a>
      </div>
      <div class="dashName" v-else>
        <span class="_title">
          {{ $t("dashName") }}
        </span>

        <!-- <span class="icon"><i class="fas fa-video mt-2"></i></span> -->
      </div>
      <div class="otherSide flex-wrap gap-3">
        <!-- <v-btn
            @click="toggleSearch"
            icon
            class="search-icon mobile-search-icon"
            color="black"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn> -->
        <!-- {{attend.attend}} -->
        <div class="search-form" v-if="false">
          <form @submit.prevent>
            <div class="input_wrapper">
              <div class="form-group">
                <v-btn icon class="search-icon" color="black">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>

                <input
                  type="text"
                  class="form-control search-input"
                  placeholder="بحث عن"
                  v-model="searchInput"
                />
              </div>
            </div>
          </form>
        </div>
        <ul class="routerIcons flex-wrap gap-3">
          <!-- LogOut -->
          <div v-if="userDataExist">
            <li v-if="userDataExist.to_day_attend">
              <multiselect
                v-if="
                  !userDataExist.to_day_attend.status_times.log_out &&
                  userDataExist.to_day_attend.status_times.log_in
                "
                class="simple_border"
                v-model="user_status"
                :options="status"
                :label="$t('labels.Attendstatus')"
                track-by="id"
                :placeholder="$t('labels.Attendstatus')"
                :searchable="true"
                :allow-empty="false"
                :show-labels="false"
                @input="changeStatus"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc d-flex justify-between">
                    <span class="option__title">
                      {{ props.option.name }}
                    </span>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__desc d-flex justify-between gap-2">
                    <span class="option__title">
                      {{ props.option.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </li>

            <!-- <div v-if="userDataExist.to_day_attend">
              <li @click="logOut">
                <i
                  v-if="userDataExist.to_day_attend.status_times.log_out"
                  class="fa fa-sign-out"
                ></i>
              </li>
            </div> -->
          </div>
          <li @click="logOut">
            <i class="fa fa-sign-out"></i>
          </li>
          <!-- Notifications -->
          <li
            class="notification_list_item"
            @click.stop="toggle_notification_menu"
          >
            <div class="notification_icon">
              <i class="fa fa-bell"></i>
              <span v-if="unreadNotifCount > 0" class="notificationNumber">
                {{ unreadNotifCount }}
              </span>
            </div>
            <!-- notification dropdown -->
            <transition name="fadeUpTransform">
              <div
                v-if="notification_menu"
                class="custom_card notification_dropdown nav_dropdown"
              >
                <header class="header">
                  <h3>
                    {{ unreadNotifCount }}
                    {{ $t("navbar.notification.topTitle_1") }}
                  </h3>
                  <p>{{ $t("navbar.notification.topTitle_2") }}</p>
                </header>
                <!-- If Empty -->
                <div
                  class="empty_notifications_wrapper d-flex flex-column justify-content-center align-items-center py-4"
                  v-if="notifications.length == 0"
                >
                  <img
                    src="@/assets/media/images/Others/no_notifications.svg"
                    alt="No Notifications"
                    width="120"
                    height="150"
                  />
                  <h4>{{ $t("navbar.notification.no_notifications") }}</h4>
                </div>
                <!-- Else -->
                <ul class="notifications_container" v-else>
                  <li
                    v-for="item in notifications"
                    :key="item.id"
                    @click="readMessage(item)"
                  >
                    <div class="item" :class="item.read_at ? 'read' : ''">
                      <span class="icon">
                        <i class="far fa-bell"></i>
                      </span>
                      <div class="text">
                        <h4>{{ item.title[$i18n.locale] }}</h4>
                        <p :title="item.body[$i18n.locale]">
                          {{
                            item.body[$i18n.locale].slice(0, 40) +
                            `${
                              item.body[$i18n.locale].length > 40 ? "..." : ""
                            }`
                          }}
                        </p>
                      </div>
                      <span class="time">{{ item.created_time }}</span>
                    </div>
                  </li>
                </ul>
                <!-- <footer v-if="notifications.length">
                  <router-link to="/notifications/show-all">{{
                    $t("breadcrumb.notifications.all")
                  }}</router-link>
                </footer> -->
              </div>
            </transition>
          </li>
          <!-- Theme -->
          <li v-if="current_theme == 'dark'" @click="changeTheme('light')">
            <i class="fal fa-sun"></i>
          </li>
          <li @click="switchLocale()">
            <i class="fal fa-globe"></i>
          </li>
        </ul>
        <!-- Mobile Menue -->
        <span @click.stop="toggleMenu" class="toggleMenue">
          <i class="fa fa-bars"></i>
        </span>
      </div>
    </div>
    <ul
      v-if="userDataExist"
      class="d-flex w-100 gap-2 align-items-center justify-content-between flex-wrap pb-2 pe-2"
    >
      <div>
        <li v-if="userDataExist.to_day_attend" class="attend_status">
          <!-- v-if="!attend.attend" -->

          {{
            $t("labels.log_in_date") +
            ":" +
            " " +
            new Date(
              userDataExist.to_day_attend.status_times.log_in
            ).toLocaleString()
          }}
          <!-- <button @click="toggelAttend" v-if="attend.attend &&  attend.attend.status=='log_in'" class="attend_button withdrawal">
              {{$t("TITLES.withdrawal")}}
            </button> -->
        </li>
      </div>
      <div>
        <li v-if="!userDataExist.to_day_attend">
          <!-- v-if="!attend.attend" -->
          <button @click="attend" class="attend_button ps-2">
            {{ $t("TITLES.attend") }}
          </button>
          <!-- <button @click="toggelAttend" v-if="attend.attend &&  attend.attend.status=='log_in'" class="attend_button withdrawal">
              {{$t("TITLES.withdrawal")}}
            </button> -->
        </li>
        <li class="withdrawal">
          <div v-if="userDataExist.to_day_attend">
            <!-- <button
              class="attend_button"
              v-if="!userDataExist.to_day_attend.status_times.log_out"
              @click="withdrawl"
            >
              {{ $t("TITLES.withdrawal") }}
            </button> -->
            <span
              class="attend_status"
              v-if="userDataExist.to_day_attend.status_times.log_out"
            >
              {{
                $t("labels.log_out_date") +
                ":" +
                " " +
                new Date(
                  userDataExist.to_day_attend.status_times.log_out
                ).toLocaleString()
              }}
            </span>
          </div>
          <!-- <i class="fa fa-sign-out"></i> -->
        </li>
      </div>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      profile_menu: false,
      notification_menu: false,
      searchInput: "",
      userDataExist: null,
      inactivityTimer: null,

      user_status: null,
      status: [
        {
          name: this.$t("labels.ACW"),
          id: "after_call_work",
        },
        {
          name: this.$t("labels.on_training"),
          id: "training",
        },
        {
          name: this.$t("labels.on_meeting"),
          id: "at_meeting",
        },
        {
          name: this.$t("labels.activate"),
          id: "active",
        },

        {
          name: this.$t("labels.break"),
          id: "break",
        },
      ],
      allStatus: [
        {
          name: this.$t("labels.on_training"),
          id: "training",
        },
        {
          name: this.$t("labels.on_meeting"),
          id: "at_meeting",
        },
        {
          name: this.$t("labels.ACW"),
          id: "after_call_work",
        },
        {
          name: this.$t("labels.return_from_after_call_work"),
          id: "return_from_after_call_work",
        },
        {
          name: this.$t("labels.break"),
          id: "break",
        },
        {
          name: this.$t("labels.away"),
          id: "away",
        },
        {
          name: this.$t("labels.activate"),
          id: "active",
        },
      ],
      statusToChangeAuto: null,
    };
  },

  methods: {
    getUserData() {
      this.axios.get("profile").then((res) => {
        this.userDataExist = res.data.data;
        if (res.data.data.to_day_attend) {
          this.user_status = this.allStatus.find(
            (el) => el.id == res.data.data.to_day_attend.status
          );
        } else {
          this.attend();
          // this.$swal({
          //   title: this.$t("youAreNotYetLoggedIn"),
          //   // text: `${this.$t(payload.data.sender.user_type)}  ${this.$t(
          //   //   payload.data.sender.name
          //   // )}`,
          //   icon: "warning",
          //   showCancelButton: true,
          //   confirmButtonColor: "#3085d6",
          //   cancelButtonColor: "#d33",
          //   cancelButtonText: this.$t("cancel"),
          //   confirmButtonText: this.$t("TITLES.attend"),
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     this.attend();
          //   }
          // });
        }

        this.startInactivityTimer();
      });
    },
    toggleSearch() {
      let searchForm = document.querySelector(".search-form .form-group");

      searchForm.classList.toggle("show");
    },
    toggle_profile_menu() {
      this.profile_menu = !this.profile_menu;
      this.notification_menu = false;
    },
    toggle_notification_menu() {
      this.notification_menu = !this.notification_menu;
      this.profile_menu = false;
    },
    attend() {
      this.axios({
        method: "POST",
        url: "attend",
      })
        .then((res) => {
          this.userDataExist.to_day_attend = res.data.data.to_day_attend;
          this.user_status = this.allStatus.find(
            (el) => el.id == res.data.data.to_day_attend.status
          );
          this.startInactivityTimer();
        })
        .catch((err) => {
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response.data.message,
          });
        });
    },
    withdrawl() {
      this.axios({
        method: "POST",
        url: `attend/withdrawal/${this.userDataExist.to_day_attend.id}`,
      })
        .then((res) => {
          this.userDataExist.to_day_attend = res.data.data.to_day_attend;
          this.$store.dispatch("auth_module/logOut");
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$iziToast.error({
            displayMode: 2,
            title: this.$t("error"),
            message: err.response.data.message,
          });
        });
    },

    toggleMenu() {
      document.querySelector(".smallScreenSidebar").classList.toggle("active");
    },
    logOut() {
      if (this.userDataExist.to_day_attend?.status == "log_out") {
        this.$store.dispatch("auth_module/logOut");
      } else {
        this.$swal({
          title: this.$t("youAreAboutToSignOutAndLeave"),
          // text: `${this.$t(payload.data.sender.user_type)}  ${this.$t(
          //   payload.data.sender.name
          // )}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("cancel"),
          confirmButtonText: this.$t("TITLES.withdrawal"),
        }).then((result) => {
          if (result.isConfirmed) {
            // this.attend();
            this.withdrawl();
          }
        });
      }
    },
    changeTheme(value) {
      this.$store.dispatch("theme_module/changeTheme", value);
      if (value == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },
    switchLocale() {
      this.$store.dispatch("lang_module/switchLang");
    },
    readMessage(item) {
      this.$router.push(`/tickets/reply/${item.data_id}`).catch(() => {});

      // :to="`/${getRouteLink(item)}/show/${item.data_id}`"
    },
    changeStatus(status) {
      const data = new FormData();

      data.append("status", status.id ?? status);
      this.axios({
        method: "POST",
        url: `attend/change-status/${this.userDataExist.to_day_attend.id}`,
        data: data,
      })
        .then((res) => {
          this.userDataExist.to_day_attend = res.data.data.to_day_attend;
          this.user_status = this.allStatus.find(
            (el) => el.id == this.userDataExist.to_day_attend.status
          );

          this.$iziToast.success({
            displayMode: 2,
            title: this.$t("success"),
            message: this.$t(
              `labels.${
                this.userDataExist.to_day_attend.status == "active"
                  ? "activate"
                  : this.userDataExist.to_day_attend.status
              }`
            ),
          });
          // this.startInactivityTimer();
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$iziToast.error({
              displayMode: 2,
              title: this.$t("error"),
              message: err.response.data.errors.message[0],
            });
          } else {
            this.$iziToast.error({
              displayMode: 2,
              title: this.$t("error"),
              message: err.response.data.message ?? err.response.data.messages,
            });
          }
          this.loading = false;
        });
    },
    getRouteLink(item) {
      let link;
      if (item.notify_type == "new_trip") {
        link = "trips";
      } else if (item.notify_type == "new_rider") {
        link = "riders";
      } else if (item.notify_type == "new_partner") {
        link = "partners";
      } else if (item.notify_type == "finish_trip") {
        link = "trips";
      }

      return link;
    },
    startInactivityTimer() {
      if (this.userDataExist?.to_day_attend) {
        if (this.userDataExist.to_day_attend.status == "away") {
          if (this.statusToChangeAuto != "waitActive") {
            this.changeStatus("active");
          }

          this.statusToChangeAuto = "waitActive";
        }
      }

      clearTimeout(this.inactivityTimer);

      this.inactivityTimer = setTimeout(
        () => this.inactiveAction(),
        5 * 60 * 1000
      ); // 5 minutes
    },
    inactiveAction() {
      if (this.userDataExist.to_day_attend) {
        if (this.userDataExist.to_day_attend.status == "active") {
          if (this.statusToChangeAuto != "waitAway") {
            this.changeStatus("away");
          }
          this.statusToChangeAuto = "waitAway";
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      current_theme: "theme_module/current_theme",
      // attend: "auth_module/currentUser",
      lang: "lang_module/lang",
      notifications: "notifications_module/notifications",
      unreadNotifCount: "notifications_module/unreadCount",
    }),
  },
  mounted() {
    this.getUserData();

    document.addEventListener("mousemove", () => {
      this.startInactivityTimer();
    });
  },
};
</script>
<style lang="scss">
.attend_button {
  font-size: 16px;
  min-width: 150px;
  font-weight: 400;
  text-align: center;
  background: var(--main);
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  // background: linear-gradient(120deg, var(--softMain) 50%, var(--main) 50%);
}
.attend_status {
  font-size: 16px;
  color: var(--main);
}

.search-form {
  flex: 1;
  margin-inline: 10px;
  .form-group {
    display: flex;
    align-items: center;
    position: relative;
    background: var(--mainBg1);
    border-radius: 8px;
    .v-text-field__details {
      display: none;
    }
    input.form-control.search-input {
      background: var(--mainBgColor) !important;
      padding-right: 35px;
      color: var(--blackColor) !important;
      height: 45px;
      border: 0;
      outline: 0 !important;
      box-shadow: none !important;
      color: var(--main) !important;
    }
    button.search-icon {
      position: absolute;
      right: -1px;
      color: var(--main) !important;
    }
    .v-select__selection {
      width: 100%;
    }
    .v-btn__content {
      color: var(--blackColor) !important;
      height: 45px;
    }
    .select-category {
      position: absolute;
      left: 3px;
      height: 40px;
      padding: 4px;
      width: 140px;
      color: var(--blackColor) !important;
      margin: 0;
      border: 1px solid var(--borderColor);
      border-radius: 0.375rem;
      background: var(--mainBgColor);
      padding: 0;
      .v-select__selections {
        display: flex;
        align-items: center;
      }
      input[role="readonly"] {
        display: none;
      }
      .v-select__slot {
        padding: 5px;
      }
      .v-input__slot:after {
        content: unset !important;
      }
      div[role="button"] {
        height: 42px;
        margin: 0 !important;
        bottom: 1px;
        &::before {
          content: unset;
        }
        display: flex;
        align-items: center;
        * {
          margin: 0;
          color: var(--blackColor) !important;
          font-size: 14px;
        }
      }
    }
  }
}
.otherSide .mobile-search-icon {
  @media (min-width: 559px) {
    display: none;
  }
}
.otherSide .form-group {
  input.search-input {
    padding-right: 35px;
    padding-left: 150px;
  }
  @media (max-width: 559px) {
    display: none;
    &.show {
      display: flex;
    }
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
  }
}
</style>
