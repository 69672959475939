export default {
  setNotifications(state, payload) {
    state.notifications = payload;
  },
  setUnread(state, payload) {
    state.unreadCount = payload;
  },
  pushNotification(state, payload) {
    state.notifications.push(payload);
    ++state.unreadCount;
  },
  decreaseCounter(state) {
    --state.unreadCount;
  },
  readNotification(state, payload) {
    const found = state.notifications.find((el) => el.data_id == payload);
    if (found) {
      state.notifications = state.notifications.filter(
        (el) => el.data_id != payload
      );

      --state.unreadCount;
    }
  },
};
