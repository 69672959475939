import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
  namespaced: true,
  state() {
    return {
      user: {
        id: localStorage.getItem('Bro_agent_User_Id'),
        token: localStorage.getItem('Bro_agent_Token'),
        usertype: localStorage.getItem('Bro_agent_User_Type'),
        name: localStorage.getItem('Bro_agent_User_name'),
        image: localStorage.getItem('Bro_agent_userImg'),
        phone: localStorage.getItem('Bro_agent_userPhone'),
        attend:localStorage.getItem('Bro_agent_userAttend') ? JSON.parse(localStorage.getItem('Bro_agent_userAttend')) : localStorage.getItem('Bro_agent_userAttend')
      },
    }
  },
  mutations,
  actions,
  getters,
}
