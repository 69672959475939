export default {
  // ============================= Set_User_Dataa
  set_userId(state, payload) {
    localStorage.setItem('Bro_agent_User_Id', payload)
    state.user.id = payload
  },
  set_token(state, payload) {
    localStorage.setItem('Bro_agent_Token', payload)
    state.user.token = payload
  },
  set_userName(state, payload) {
    localStorage.setItem('Bro_agent_User_name', payload)
    state.user.name = payload
  },
  set_usertype(state, payload) {
    localStorage.setItem('Bro_agent_User_Type', payload)
    state.user.usertype = payload
  },
  set_userImage(state, payload) {
    localStorage.setItem('Bro_agent_userImg', payload)
    state.user.image = payload
  },
  set_userPhone(state, payload) {
    localStorage.setItem('Bro_agent_userPhone', payload)
    state.user.phone = payload
  },
  set_userAttend(state, payload) {
    localStorage.setItem('Bro_agent_userAttend',JSON.stringify(payload) )
    state.user.attend = payload
  },
  // ============================= Remove_User_Dataa
  remove_user_data(state) {
    localStorage.removeItem('Bro_agent_User_Id')
    localStorage.removeItem('Bro_agent_Token')
    localStorage.removeItem('Bro_agent_User_Type')
    localStorage.removeItem('Bro_agent_userImg')
    localStorage.removeItem('Bro_agent_userPhone')
    localStorage.removeItem('Bro_agent_userAttend')
    state.user.id = null
    state.user.token = null
    state.user.image = null
    state.user.usertype = null
    state.user.phone = null
    state.user.attend = null
  },
}
