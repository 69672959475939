import axios from "axios";
// import store from "@/store/index";

export default {
  // ============ Logout
  logOut(context) {
    axios({
      method: "post",
      url: "logout",
    })
      .then((res) => {
        context.commit("remove_user_data");
        location.reload();
        this.$iziToast.success({
          displayMode: 2,
          title: this.$t("validation.success"),
          message: res.response.data.message,
        });
      })
      .catch((err) => {
        context.commit("remove_user_data");
        location.reload();
        this.$iziToast.error({
          displayMode: 2,
          title: this.$t("validation.error"),
          message: err.response.data.message,
        });
      });
    // if(context.getters.currentUser.attend?.status=='log_out'  ){
    // }else{
    //   axios({
    //       method: "POST",
    //       url: `attend/withdrawal/${context.getters.currentUser.attend.id}`,
    //     })
    //       .then(() => {
    //           // Start  Logout
    //           axios({
    //             method: 'post',
    //             url: 'logout',
    //           })
    //             .then((res) => {
    //               context.commit('remove_user_data')
    //               // console.log("fdkjhgkjdhfk")
    //               location.reload()
    //               this.$iziToast.success({
    //                 displayMode: 2,
    //                 title: this.$t('validation.success'),
    //                 message: res.response.data.message,
    //               })
    //             })
    //             .catch((err) => {
    //               this.$iziToast.error({
    //                 displayMode: 2,
    //                 title: this.$t('validation.error'),
    //                 message: err.response.data.message,
    //               })
    //             })
    //       })
    //       .catch((err) => {
    //         this.$iziToast.error({
    //           displayMode: 2,
    //           title: this.$t("error"),
    //           message: err.response.data.message,
    //         });
    //       });
    // }
  },

  // ============ Login
  logIn(context, payload) {
    console.log(payload);
    context.commit("set_userId", payload?.userId || null);
    context.commit("set_token", payload?.token || null);
    context.commit("set_usertype", payload?.userType || null);
    context.commit("set_userName", payload?.username || null);
    context.commit("set_userImage", payload?.userImage || null);
    context.commit("set_userPhone", payload?.userPhone || null);
    context.commit("set_userAttend", payload?.userAttend || null);
    location.reload();
  },
};

